import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import axios from 'axios';
import VueClipboard from 'vue-clipboard2'; 
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import pinia from "./store"


const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.use(pinia)
app.use(VueClipboard);  
app.provide('$axios',axios)
app.mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
