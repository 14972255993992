import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   component: () => import("@/views/index/index.vue"),
  // },
  {
    path: "/mobile",
    name:"Index",
    redirect: "/mobile/index",
    children:[
      {
        path: "index",
        name: "mobileIndex",
        component: () => import("@/views/mobile/index.vue"),
      },
      {
        path: "tenTang",
        name: "mobileTentang",
        component: () => import("@/views/mobile/tentang/index.vue"),
      },
			{
			  path: "kontakKami",
			  name: "mobileKontakKami",
			  component: () => import("@/views/mobile/kontakKami/index.vue"),
			},
			{
			  path: "mobaiexp",
			  name: "mobileMobaiexp",
			  component: () => import("@/views/mobile/mobaiexp/index.vue"),
			},
			{
			  path: "speechToText",
			  name: "mobileSpeechToText",
			  component: () => import("@/views/mobile/speechToText/index.vue"),
			},
			
			{
			  path: "textToSpeech",
			  name: "mobileTextToSpeech",
			  component: () => import("@/views/mobile/textToSpeech/index.vue"),
			},
			{
			  path: "trunk",
			  name: "mobileTrunk",
			  component: () => import("@/views/mobile/trunk/index.vue"),
			},
			{
			  path: "Koikoll",
			  name: "mobileKoikoll",
			  component: () => import("@/views/mobile/Koikoll/index.vue"),
			},
			{
			  path: "SMSPremium",
			  name: "mobileSMSPremium",
			  component: () => import("@/views/mobile/SMSPremium/index.vue"),
			},
			{
			  path: "businessAPI",
			  name: "mobileBusinessAPI",
			  component: () => import("@/views/mobile/businessAPI/index.vue"),
			},
			{
			  path: "smartNameTag",
			  name: "mobileSmartNameTag",
			  component: () => import("@/views/mobile/smartNameTag/index.vue"),
			},
    ]
  },
  {
    path: "/Tentang",
    name: "Tentang",
    component: () => import("@/views/Tentang/index.vue"),
  },
	{
	  path: "/kontakKami",
	  name: "kontakKami",
	  component: () => import("@/views/kontakKami/index.vue"),
	},
	{
	  path: "/trunk",
	  name: "trunk",
	  component: () => import("@/views/trunk/index.vue"),
	},
	{
	  path: "/Koikoll",
	  name: "Koikoll",
	  component: () => import("@/views/Koikoll/index.vue"),
	},
	{
	  path: "/SMSPremium",
	  name: "SMSPremium",
	  component: () => import("@/views/SMSPremium/index.vue"),
	},
	{
	  path: "/businessAPI",
	  name: "businessAPI",
	  component: () => import("@/views/businessAPI/index.vue"),
	},
	{
	  path: "/smartNameTag",
	  name: "smartNameTag",
	  component: () => import("@/views/smartNameTag/index.vue"),
	},
	{
	  path: "/Telecollection",
	  name: "Telecollection",
	  component: () => import("@/views/Telecollection/index.vue"),
	},
	{
	  path: "/CustomerService",
	  name: "CustomerService",
	  component: () => import("@/views/CustomerService/index.vue"),
	},
	{
	  path: "/LeadGeneration",
	  name: "LeadGeneration",
	  component: () => import("@/views/LeadGeneration/index.vue"),
	},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
	setTimeout(function() {
	  window.scroll({
	    top: 0,
	    behavior: "smooth",
	  });
	}, 5);
 
  next();
});

export default router;
