import {defineStore} from "pinia"

type test  = {
  name:string
}
export const useTest = defineStore("test",{
  state:():test=>({ name:"111"}),
  getters:{

  },
  actions:{
    
  }
})